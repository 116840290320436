import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'

const TableHeadingFilter = ({filterInstrumentsByValue, attribute, sortData}) => {

    const [minValue, setMinValue] = useState()
    const [maxValue, setMaxValue] = useState()
    const [isAssending, setIsAssending] =  useState(true)


    const shortUserData = (attribute, isAssending) =>{

        sortData(attribute, isAssending)

        setIsAssending((item) =>{
            return !item
        })

    }



    const handleFilter = (e) => {
        const placeholderValue = e.target.placeholder
        const searchValue = e.target.value

        if (placeholderValue == 'min'){
            setMinValue(searchValue)
            if (searchValue){
                filterInstrumentsByValue(searchValue, maxValue, attribute)
            } else {
                filterInstrumentsByValue(undefined, maxValue, attribute)
            }
            
        } else if (placeholderValue == 'max'){
            setMaxValue(searchValue)
            if (searchValue){
                filterInstrumentsByValue(minValue, searchValue, attribute)
            } else {
                filterInstrumentsByValue(minValue, undefined, attribute)
            }
        }
    }

  return (
    <Dropdown style={{background:'lightgrey'}}>
        <Dropdown.Toggle variant="" size='sm'>
            {/* <i className="fa fa-filter"></i> */}
        </Dropdown.Toggle>
        <Dropdown.Menu style={{padding:'.1rem'}}>

            {/* <input style={{border:'0.16rem solid grey', borderRadius:'0.35rem', marginBottom:'0.15rem'}} type='text' placeholder='search items' onChange={filterInstruments}></input> */}

            {/* Dropdown.Item : Because this element disappears the dropdown Menu from display */}
            <Dropdown.Item style={{padding:'0px'}}>
                <div style={{border:'0.1rem solid lightgrey', borderRadius:'0.35rem', marginBottom:'0.19rem', background:'lightblue'}} onClick={() =>shortUserData(attribute, isAssending)}>
                Short <i className="fa fa-arrows-v" style={{fontSize:'12px', color:'grey'}}></i>
                </div>
            </Dropdown.Item>

            <div style={{border:'0.1rem solid lightgrey', borderRadius:'0.35rem', padding:'1px', marginBottom:'0.19rem'}}>
                <div >Filter by Value</div>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                    {/* <input type='number' value={minValue} style={{maxWidth:'3.7rem', borderRadius:'0.3rem', border:'1px solid grey'}} placeholder='min' onChange={(e) =>(filterInstrumentsByValue(e, null))} ></input> */}
                    <input type='number' value={minValue} style={{maxWidth:'3.7rem', borderRadius:'0.3rem', border:'1px solid lightgrey'}} placeholder='min' onChange={handleFilter} ></input>
                    &
                    <input type='number' value={maxValue} style={{maxWidth:'3.7rem', borderRadius:'0.3rem', border:'1px solid lightgrey'}} placeholder='max' onChange={handleFilter}></input>
                </div>
            </div>
            <Dropdown.Item style={{padding:'0px'}}>
                <div style={{border:'0.1rem solid lightgrey', borderRadius:'0.35rem', background:'#ffb3b2', marginBottom:'0.1rem'}}>Clear filter</div>
            </Dropdown.Item>

        </Dropdown.Menu>
    </Dropdown>
  )
}

// ERROR: non default exported methods should have "export" identifier before variable
export const TableHeadingFilterSearchOnly = ({filterInstrumentsBySearch, attribute}) => {

    const handleSearch = (e) =>{

        filterInstrumentsBySearch(e, attribute)
    }
    return (
        <Dropdown>
        <Dropdown.Toggle variant="" size='sm'>
        </Dropdown.Toggle>
        <Dropdown.Menu style={{padding:'.1rem'}}>
            <input style={{border:'0.1rem solid grey', borderRadius:'0.35rem'}} type='text' placeholder='search items' onChange={handleSearch} onKeyDown={handleSearch}></input>
        </Dropdown.Menu>
        </Dropdown>
    )
  }
export default TableHeadingFilter;