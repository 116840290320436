import React, { useState } from 'react';
import axios from 'axios';
import { Form, Nav, FloatingLabel, Badge, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../constants';

const UserLogIn = () => {
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [message, setMessage] = useState(null);
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${API_URL}/login`, { email, password });
            localStorage.setItem('token', response.data.access_token);
            setMessage('Login successful');
            navigate('/');
            window.location.reload();

        } catch (error) {
            setMessage('Login failed');
        }
    };
    return (

        <div className='logInForm' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{minWidth: '24rem', maxWidth: '60vw', marginTop: '5rem', padding: '1rem', border:'grey solid 0.05rem', boxShadow:'10px 10px 10px 10px lightgrey', backgroundImage: 'linear-gradient(to left, lightblue , lightgrey)', borderRadius: '0.5rem'}}>
            <h1 style={{marginBottom: '1rem'}}><Badge bg="secondary" size="l">Sing In</Badge></h1>
                <Form onSubmit={handleLogin}>
                    <FloatingLabel
                    controlId="floatingInput"
                    label="Email address"
                    className="mb-3"
                    >
                    <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder=""/>
                    </FloatingLabel>

                    <FloatingLabel 
                    controlId="floatingPassword" 
                    label="Password"
                    >
                    <Form.Control type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
                    </FloatingLabel>
        
                    <div className="d-grid gap-2" style={{marginTop: '1rem', marginBottom: '1rem'}}>
                    <Button variant="primary" size="m" type='submit'>
                        Sign In
                    </Button>
                    </div>
                </Form>
                <div>
                    <p style={{color: 'blue', textDecoration:'underline'}}>
                        <a href="/user/forgotPassword">forgot password</a>
                    </p>
                </div>
                <div> <h5>not a User ?</h5> <span style={{textDecoration: 'underline'}}><Nav.Link href="/user/UserRegistration"><h3>Register Here</h3></Nav.Link></span></div>
                {message && <div class="alert alert-dark" role="alert"> {message}</div>}
            </div>
        </div>
    );
};

export default UserLogIn