import React, { useState } from 'react';
import axios from 'axios';
import { Form, Nav, FloatingLabel, Badge, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../constants';

const UserRegistration = () => {

  const [email, setEmail] = useState(null);
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${API_URL}/register`, {
        email: email,
        date: new Date(),

      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      });

      setMessage(response.data.message);

      setTimeout(() => {
        navigate('/user/logIn');
        window.location.reload()
      }, 2000);

    } catch (error) {
      setMessage('Registration failed');
      setTimeout(() => {
        setMessage('')
      }, 5000);
    }
  };

  return (
    <div className='RegistrationForm' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <div style={{minWidth: '24rem', maxWidth: '60vw', display: 'inline-block', marginTop: '5rem', padding: '1rem', border:'grey solid 0.05rem', boxShadow:'10px 10px 10px 10px lightgrey', backgroundImage: 'linear-gradient(to right, lightblue , lightgrey)', borderRadius: '0.5rem'}}>
        <h1 style={{marginBottom: '1rem'}}><Badge bg="secondary" size="l">New Registration</Badge></h1>
        <form onSubmit={handleRegister}>
          <FloatingLabel
            controlId="floatingInput"
            label="Email address"
            className="mb-3"
            >
            <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder=""/>
          </FloatingLabel>

          <div className="d-grid gap-2" style={{marginTop: '1rem', marginBottom: '1rem'}}>
            <Button variant="primary" size="m" type='submit'>
              Register
            </Button>
          </div>
        </form>
        <div> <h5>already registered ?</h5> <span style={{textDecoration: 'underline'}}><Nav.Link href="/user/logIn"><h3>Sign In</h3></Nav.Link></span></div>
        {message && <div class="alert alert-dark" role="alert"> {message}</div>}
      </div>
    </div>
  );

};

export default UserRegistration