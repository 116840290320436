import axios from "axios";
import React, { useEffect, useState } from "react";
import { Badge, Button, Nav} from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { API_URL } from "../../constants";

const UserProfile = ({currentUser}) =>{

    const [isEditable, setIsEditable] = useState(false);
    const [msg , setMsg] = useState("Loading User ...")
    const [error, setError] = useState(null);
    const token = localStorage.getItem('token')
    const navigate = useNavigate();

    const [userName, setUserName] = useState(currentUser ? currentUser.username : null);
    const [userEmail, setUserEmail] = useState(currentUser ? currentUser.email : null);
    const [userId, setUserId] = useState(currentUser ? currentUser.userId : null);
    const [userMobile, setUserMobile] = useState(currentUser ? currentUser.mobile : null);
    const [userCountry, setUserCountry] = useState(currentUser ? currentUser.country : null);
    
    const [logOutMsg, setLogOutMsg] = useState(null);


    const handleLogOut = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${API_URL}/logout`, {headers:{
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            setLogOutMsg('Logout successfully');
            navigate('/logout')
            window.location.reload()

        } catch (error) {
            setLogOutMsg('Logout failed');
            localStorage.removeItem('token');

            setLogOutMsg('Logout successfully');
            navigate('/logout')
            window.location.reload()

        }
    };

    const handleProfileUpdate = async (e) =>{
        e.preventDefault()

        try {

            const update_response = await axios.post(
                `${API_URL}/updateUserProfile`,
                {
                  'userEmail': userEmail,
                  'userMobile': userMobile,
                  'userCountry': userCountry,
                  'userName': userName
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                  }
                }
              );

              if (update_response.status == 202){
                localStorage.setItem('token', update_response.data.access_token);
              }
        
              if (update_response.status !== 202) {
                setError(update_response.data.message || 'Error updating profile');
              } else {
                console.log("Profile updated successfully");
              }
            } catch (error) {
              setError(error.message || 'An error occurred');
            }
          };

    setTimeout(() =>{
        const isUserValid = currentUser ? true : "Please sign in first !";
        setMsg(isUserValid);
    } ,1000)

    useEffect(()=>{
        setUserEmail(currentUser ? currentUser.email : null)
        setUserId(currentUser ? currentUser.userId : null)
        setUserMobile(currentUser ? currentUser.mobile : null)
        setUserName(currentUser ? currentUser.username : null)
        setUserCountry(currentUser ? currentUser.country : null)
    }, [currentUser])

    return(
        <>
        {currentUser ? 
                <div className='logInForm' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{maxWidth: '90vw', minWidth: '29rem', marginTop: '5rem', padding: '1rem', border:'grey solid 0.05rem', boxShadow:'10px 10px 10px 10px lightgrey', backgroundImage: 'linear-gradient(to left, lightblue , lightgrey)', borderRadius: '0.5rem'}}>
                        <h1 style={{marginBottom: '1rem'}}><Badge bg="secondary" size="m">User Profile</Badge>
                            <Button style={{marginLeft: '1rem'}} variant="danger" onClick={handleLogOut}>log Out</Button>
                        </h1>
                        <form style={{display: 'grid', justifyContent: 'center'}} onSubmit={handleProfileUpdate}>
                            <div style={{display: 'flex', margin: '0.5rem'}}>
                            <label>User Id: </label>
                            <input style={{background:'transparent', border: 'None', marginLeft: '1rem'}}
                                type="text"
                                name="userId"
                                placeholder="userId"
                                value={userId}
                                disabled
                            >
                            </input>
                            </div>

                            <div style={{display: 'flex', margin: '0.5rem'}}>
                            <label>User Name: </label>
                            <input style={{ background:'transparent', border: 'None', marginLeft: '1rem', border: isEditable ? '1px solid grey' : 'none'}}
                                type="text"
                                name="userName"
                                placeholder="User Name"
                                value={userName}
                                disabled={!isEditable}
                                onChange={(e) => {setUserName(e.target.value);}}
                            >
                            </input>
                            </div>

                            <div style={{display: 'flex', margin: '0.5rem'}}>
                            <label>E-mail: </label>
                            <input style={{background:'transparent', border: 'None', marginLeft: '1rem'}}
                                type="text"
                                name="Name"
                                placeholder='user@company.com'
                                value={userEmail}
                                disabled
                                onChange={(e) => setUserEmail(e.target.value)}
                            >
                            </input>
                            </div>

                            <div style={{display: 'flex', margin: '0.5rem'}}>
                            <label>User type: </label>
                            <input style={{background:'transparent', border: 'None', marginLeft: '1rem'}}
                                type="text"
                                name="Name"
                                placeholder="basic"
                                value={currentUser.userType}
                                disabled
                            >
                            </input>
                            </div>
                            <div style={{display: 'flex', margin: '0.5rem'}}>
                            <label>mobile : </label>
                            <input style={{background:'transparent', marginLeft: '1rem', borderRadius: '0.25rem', border: isEditable ? '1px solid grey' : 'none'}}
                                type="text"
                                name="userMobile"
                                placeholder="Mobile Number"
                                value={userMobile}
                                disabled={!isEditable}
                                onChange={(e) => setUserMobile(e.target.value)}
                            >
                            </input>
                            </div>
                            <div style={{display: 'flex', margin: '0.5rem'}}>
                            <label>Country : </label>
                            <input style={{background:'transparent', marginLeft: '1rem', borderRadius: '0.25rem', border: isEditable ? '1px solid grey' : 'none'}}
                                type="text"
                                name="Name"
                                placeholder="Country"
                                value={userCountry}
                                disabled={!isEditable}
                                onChange={(e) => setUserCountry(e.target.value)}
                            >
                            </input>
                            
                            </div>
                            <div style={{marginTop: '1rem'}}><span style={{textDecoration: 'underline'}}><Nav.Link href="/user/resetPassword"><p>Reset Password</p></Nav.Link></span></div>
                            <Button style={{marginTop: '1rem', display: !isEditable ? 'none':null}} variant="outline-primary" onClick={() => (setIsEditable( e => !e))} type='submit'>Submit</Button>
                            <Button style={{marginTop: '1rem', display: isEditable ? 'none':null}} variant="outline-primary" onClick={() => (setIsEditable( e => !e))}>Edit profile</Button>
                        </form>
                        
                    </div>
                </div>
            :
            <h4> {msg}</h4>
        }
        </>
    )
};

export default UserProfile