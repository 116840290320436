import axios from 'axios';
import React from 'react'
import { useState } from 'react';

import { Badge, Form, FloatingLabel, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../constants';

const UserForgetPassword = () => {

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();


  const handleForgotPassword = async (e) =>{
    e.preventDefault();

    try {
      const response = await axios.post(`${API_URL}/forgotPassword`, {email});

      setMessage(response.data.msg)

      if (response.status == 200) {
        setTimeout(() => {
          navigate('/user/logIn')
        }, 2000)
      }

    } catch (e) {
      setMessage(e.response.data.msg)
    }
    
    
  }

  return (
          <div className='logInForm' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{minWidth: '24rem', maxWidth: '60vw', marginTop: '5rem', padding: '1rem', border:'grey solid 0.05rem', boxShadow:'10px 10px 10px 10px lightgrey', backgroundImage: 'linear-gradient(to left, lightblue , lightgrey)', borderRadius: '0.5rem'}}>
            <h1 style={{marginBottom: '1rem'}}><Badge bg="secondary" size="l">User Email Address</Badge></h1>
                <Form onSubmit={handleForgotPassword}>
                    <FloatingLabel
                    controlId="floatingInput"
                    label="Email address"
                    className="mb-3"
                    value={email}
                    >
                    <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder=""/>
                    </FloatingLabel>
        
                    <div className="d-grid gap-2" style={{marginTop: '1rem', marginBottom: '1rem'}}>
                    <Button variant="primary" size="m" type='submit'>
                        Submit
                    </Button>
                    </div>
                </Form>
                {message && <div className="alert alert-dark" role="warning"> {message}</div>}
            </div>
        </div>
  )
}

export default UserForgetPassword