export const API_URL = 'https://api.howismarket.com';
// export const API_URL = 'http://localhost:5000';

export const TABLE_TIME_FRME_OPTIONS = [["Intraday", true], ["Daily", false], ["Monthly", false]];

export const INTRADAY_HEADING = [
    {
        "name": "Symbol",
        "selected": true,
        "type": "str",
        "key": "symbol",
        "tip": "Symbol of the stock"
    },
    {
        "name": "Name",
        "selected": true,
        "type": "str",
        "key": "name",
        "tip": "Name of the company"
    },
    {
        "name": "Sector",
        "selected": true,
        "type": "str",
        "key": "sec",
        "tip": "Sector"
    },
    {
        "name": "LTP",
        "selected": true,
        "type": "num",
        "key": "ltp",
        "tip": "Last traded price"
    },
    {
        "name": "LTP-OP",
        "selected": true,
        "type": "num",
        "key": "ltp_op",
        "tip": "Percent difference between LTP and open price"
    },
    {
        "name": "HI-LTP",
        "selected": true,
        "type": "num",
        "key": "hi_ltp",
        "tip": "Percent difference between high and LTP"
    },
    {
        "name": "LTP-LO",
        "selected": true,
        "type": "num",
        "key": "ltp_lo",
        "tip": "Percent difference between LTP and low"
    },
    {
        "name": "LTP-VWAP",
        "selected": true,
        "type": "num",
        "key": "ltp_vwap_5min",
        "tip": "Percent difference between LTP and Volume Weighted Average price"
    },
    {
        "name": "Opening Gap",
        "selected": true,
        "type": "num",
        "key": "gapeOpen",
        "tip": "Percent gape open of the stock"
    },
    {
        "name": "Morning Range",
        "selected": true,
        "type": "num",
        "key": "morningRange",
        "tip": "Percent range of price (high and low diff. relative to LTP) before 10:15 AM"
    },
    {
        "name": "Vol. Change",
        "selected": true,
        "type": "num",
        "key": "vol_ch",
        "tip": "Percent change in volume "
    },
    {
        "name": "LTP-R2",
        "selected": true,
        "type": "num",
        "key": "ltp_r2",
        "tip": "Percent difference between LTP and R2 (Resistance level 2)"
    },
    {
        "name": "LTP-R1",
        "selected": true,
        "type": "num",
        "key": "ltp_r1",
        "tip": "Percent difference between LTP and R1 (Resistance level 1)"
    },
    {
        "name": "LTP-PI",
        "selected": true,
        "type": "num",
        "key": "ltp_pp",
        "tip": "Percent difference between LTP and Pivot Point"
    },
    {
        "name": "LTP-S1",
        "selected": true,
        "type": "num",
        "key": "ltp_s1",
        "tip": "Percent difference between LTP and S1 (Support level 1)"
    },
    {
        "name": "LTP-S2",
        "selected": true,
        "type": "num",
        "key": "ltp_s2",
        "tip": "Percent difference between LTP and S2 (Support level 2)"
    },
    {
        "name": "Open=",
        "selected": true,
        "type": "str",
        "key": "opEqulas",
        "tip": "If the open price is low or high of the day"
    },
    {
        "name": "HI-YHI",
        "selected": true,
        "type": "num",
        "key": "hi_yhi",
        "tip": "Percent difference between intraday high and yesterday high"
    },
    {
        "name": "LO-YLO",
        "selected": true,
        "type": "num",
        "key": "lo_ylo",
        "tip": "Percent difference between intraday low and yesterday low"
    },
    {
        "name": "5MIN CH",
        "selected": true,
        "type": "num",
        "key": "change_5min",
        "tip": "Percent change in price in 5 minutes"
    },
    {
        "name": "15MIN CH",
        "selected": true,
        "type": "num",
        "key": "change_15min",
        "tip": "Percent change in price in 15 minutes"
    },
    {
        "name": "Candle",
        "selected": true,
        "type": "str",
        "key": "patterns",
        "tip": "Patterns/Candles formed in intraday chart"
    }
]

export const DAILY_HEADING = [
    {
        "name": "Symbol",
        "selected": true,
        "type": "str",
        "key": "symbol",
        "tip": "Symbol of the stock"
    },
    {
        "name": "Name",
        "selected": true,
        "type": "str",
        "key": "name",
        "tip": "Name of the company"
    },
    {
        "name": "Sector",
        "selected": true,
        "type": "str",
        "key": "sec",
        "tip": "Sector"
    },
    {
        "name": "Day Change",
        "selected": true,
        "type": "num",
        "key": "ltp_op",
        "tip": "Percent difference between LTP and open price"
    },
    {
        "name": "Change",
        "selected": true,
        "type": "num",
        "key": "ltp_cl_1",
        "tip": "Percent change in price (Difference between yesterday's close price and today's open price)"
    },
    {
        "name": "Range",
        "selected": true,
        "type": "num",
        "key": "day_range",
        "tip": "Percent range of price throughout day (high and low diff relative to LTP)"
    },
    {
        "name": "Top Wick",
        "selected": true,
        "type": "num",
        "key": "topWick",
        "tip": "Top wick of the day candle"
    },
    {
        "name": "Bottom Wick",
        "selected": true,
        "type": "num",
        "key": "bottomWick",
        "tip": "Bottom wick of the day candle"
    },
    {
        "name": "Traded Value",
        "selected": true,
        "type": "num",
        "key": "tradedValue",
        "tip": "Total traded value of the stock"
    },
    {
        "name": "Volume",
        "selected": true,
        "type": "num",
        "key": "volume",
        "tip": "Total volume traded"
    },
    {
        "name": "Avg. Volume",
        "selected": true,
        "type": "num",
        "key": "avg_vol",
        "tip": "last 3 day's average volume of the stock"
    },
    {
        "name": "LTP-R2",
        "selected": true,
        "type": "num",
        "key": "ltp_r2",
        "tip": "Percent difference between LTP and R2 (Resistance level 2)"
    },
    {
        "name": "LTP-R1",
        "selected": true,
        "type": "num",
        "key": "ltp_r1",
        "tip": "Percent difference between LTP and R1 (Resistance level 1)"
    },
    {
        "name": "LTP-PI",
        "selected": true,
        "type": "num",
        "key": "ltp_pp",
        "tip": "Percent difference between LTP and Pivot Point"
    },
    {
        "name": "LTP-S1",
        "selected": true,
        "type": "num",
        "key": "ltp_s1",
        "tip": "Percent difference between LTP and S1 (Support level 1)"
    },
    {
        "name": "LTP-S2",
        "selected": true,
        "type": "num",
        "key": "ltp_s2",
        "tip": "Percent difference between LTP and S2 (Support level 2)"
    },
    {
        "name": "LTP-50DMA",
        "selected": true,
        "type": "num",
        "key": "ltp_50dma",
        "tip": "Percent difference between LTP and 50 Day Moving Average"
    },
    {
        "name": "3 Day Change",
        "selected": true,
        "type": "num",
        "key": "ch_3d",
        "tip": "Percent change in price over 3 days"
    },
    {
        "name": "5 Day change",
        "selected": true,
        "type": "num",
        "key": "ch_5d",
        "tip": "Percent change in price over 5 days"
    },
    {
        "name": "Candle",
        "selected": true,
        "type": "str",
        "key": "patterns",
        "tip": "Patterns/Candles formed in intraday chart"
    }
]

export const MONTHLY_HEADING = [
    {
        "name": "Symbol",
        "selected": true,
        "type": "str",
        "key": "symbol",
        "tip": "Symbol of the Stock"
    },
    {
        "name": "Name",
        "selected": true,
        "type": "str",
        "key": "name",
        "tip": "Name of the company"
    },
    {
        "name": "Sector",
        "selected": true,
        "type": "str",
        "key": "sec",
        "tip": "Sector"
    },
    {
        "name": "Month Change",
        "selected": true,
        "type": "num",
        "key": "month_ch",
        "tip": "Price changed this month"
    },
    {
        "name": "Range",
        "selected": true,
        "type": "num",
        "key": "month_range",
        "tip": "Percent range (difference between high and low) of this month"
    },
    {
        "name": "Top Wick",
        "selected": true,
        "type": "num",
        "key": "topWick",
        "tip": "Topwick of the current month's candle"
    },
    {
        "name": "Bottom Wick",
        "selected": true,
        "type": "num",
        "key": "bottomWick",
        "tip": "Bottom wick of the current month's candle"
    },
    {
        "name": "Traded Value",
        "selected": true,
        "type": "num",
        "key": "tradedValue",
        "tip": "Total traded valued of this month (in Crores)"
    },
    {
        "name": "Volme",
        "selected": true,
        "type": "num",
        "key": "volume",
        "tip": "Total volume traded this month"
    },
    {
        "name": "Avg. Volume",
        "selected": true,
        "type": "num",
        "key": "avg_vol",
        "tip": "Average volume of last three months"
    },
    {
        "name": "3M Change",
        "selected": true,
        "type": "num",
        "key": "change_3M",
        "tip": "Percentage price change in last three months (excluding this month)"
    },
    {
        "name": "5M Change",
        "selected": true,
        "type": "num",
        "key": "change_5M",
        "tip": "Percentage price change in last 5 months (excluding this month)"
    },
    {
        "name": "Candle",
        "selected": true,
        "type": "str",
        "key": "patterns",
        "tip": "Patterns/Candles formed in intraday chart"
    }
]