import React from 'react'
import { Container } from 'react-bootstrap'

const Footer = () => {
  return (
    <div className="Footer">
    <footer>
        <Container>
            Copyright &copy; HIM
        </Container>
    </footer>
    </div>
  )
}

export default Footer