import React, { useState } from 'react';
import axios from 'axios';
import { Form, Nav, FloatingLabel, Badge, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const UserLogOut = () => {
    const navigate = useNavigate();

    const handleClickGoHome = () =>{
        navigate('/')
        window.location.reload()
    }
    return (
        <div className='logOutPage' style={{display: 'block', justifyContent: 'center', alignItems: 'center'}}>
            <Alert variant='success'>
                User successfully logged out!
            </Alert>
            <Button variant="outline-success" onClick={handleClickGoHome}>
                    Go Home
            </Button>
        </div>
    );
};

export default UserLogOut