import React, { useEffect, useState } from 'react'
import { Button, Table, OverlayTrigger } from 'react-bootstrap';

import axios from 'axios';
import TableHeadingFilter, {TableHeadingFilterSearchOnly} from '../table/TableHeadingFilter';
import { API_URL, DAILY_HEADING, INTRADAY_HEADING, MONTHLY_HEADING, TABLE_TIME_FRME_OPTIONS } from '../../constants';

const Home = () => {

    const userPrefrences_tableHeadingOpts = localStorage.getItem("tableHeadingOpts");
    const userPrefrences_tableTimeFrameOpts = localStorage.getItem("tableTimeFrameOpts");

    // [LTP - (OP, HI, LO, CL, VWAP)], MORNING RANGE, GAP OP, VOL CH, OP=, 3 MIN CH, 5 MIN CH, PATTERN
    // [attributeName, heading selected, data type, key(as in json)]
    // {"heading": "Symbol", "enable":true, "type": "str", "key": symbol}
    const intradayHeading = INTRADAY_HEADING
    const dailyHeading = DAILY_HEADING
    // const monthlyHeading = [["Symbol", true, "str", "symbol"], ["Name", true, "str", "name"], ["Sector", true, "str", "sec"], ["Month Change", true, "num", "month_ch"], ["Range", true, "num", "month_range"], ["Top Wick", true, "num", "topWick"], ["Bottom Wick", true, "num", "bottomWick"], ["Traded Value", true, "num", "tradedValue"], ["Volme", true, "num", "volume"], ["Avg. Volume", true, "num", "avg_vol"], ["3M Change", true, "num", "change_3M"], ["5M Change", true, "num", "change_5M"], ["Candle", true, "str", "CANDLE"], ["Pattern", true, "str", "PATTERN"]]
    const monthlyHeading = MONTHLY_HEADING


    const [data, setData] = useState([]);
                                                                                                                                            // [attributeName, heading selected, data type, key(as in json)] 
    const [tableHeadingOpts, setTableHeadingOpts] = useState(userPrefrences_tableHeadingOpts ?  JSON.parse(userPrefrences_tableHeadingOpts) : intradayHeading);
    const [tableTimeFrameOpts, setTableTimeFrameOpts] = useState(userPrefrences_tableTimeFrameOpts ?  JSON.parse(userPrefrences_tableTimeFrameOpts) : TABLE_TIME_FRME_OPTIONS);
    const [filteredData, setFilteredData] = useState(null);
    const [intradayData, setIntradayData] = useState(null);
    const [dailyData, setDailyData] = useState(null);
    const [monthlyData, setMonthlyData] = useState(null);
    const [instrumentsList, setInstrumentsList] = useState(null);
    const [indexingkey, setIndexingKey] = useState("i")

    const setDataAndFilterData = (data, timeFrame) => {

        if (timeFrame == 'Daily'){
            setData(data)
            setDailyData(data)
        } 
        else if (timeFrame == 'Monthly') {
            setData(data)
            setMonthlyData(data)
        } 
        else if (timeFrame == 'Intraday') {
            setData(data)
            setIntradayData(data)
        }
    }

    const fetchData = async (timeFrame) => {
        try {
            const time_now = Date.now()

            let lastDataUpdated = localStorage.getItem('lastDataUpdateTime')
            if(lastDataUpdated) {
                lastDataUpdated = JSON.parse(lastDataUpdated)
                try {
                    const time_last_update = lastDataUpdated[timeFrame]

                    if (time_now - time_last_update < 60000) {

                        try {
                            if (timeFrame == 'Daily' && dailyData){

                                setDataAndFilterData(dailyData, timeFrame)
                                return

                            }
                            else if (timeFrame == 'Monthly' && monthlyData) {

                                setDataAndFilterData(monthlyData, timeFrame)
                                return

                            } 
                            else if (timeFrame == 'Intraday' && intradayData) {

                                setDataAndFilterData(intradayData, timeFrame)
                                return

                            }
                            
                        } catch (error) {

                            console.log(error)
                            
                        }

                    }
                } catch (error) {

                    localStorage.setItem('lastDataUpdateTime', JSON.stringify({[timeFrame]:time_now}))

                }

                localStorage.setItem('lastDataUpdateTime', JSON.stringify({...lastDataUpdated, [timeFrame]:time_now}))
            }
            else {

                localStorage.setItem('lastDataUpdateTime', JSON.stringify({[timeFrame]:time_now}))

            }

            const token = localStorage.getItem('token')

            const response = await axios.get( API_URL, {

                headers:{
                    timeFrame: timeFrame,
                    Authorization: `Bearer ${token}`
                }

            })

            calculateTableData2(response.data, timeFrame)

            if (response.status != 200) {
            throw new Error('Network response was not ok');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }


    const resetFilteredData = () => {

        const newFilteredData = instrumentsList.reduce((acc, item) => {

            const accValue = tableHeadingOpts.reduce((accumulator, attr) => {

                return {...accumulator, [attr['name']]: true}

            }, {})

            acc[item] = accValue
            return acc;
        }, {});

        setFilteredData(newFilteredData)

    }

    const calculateTableData2 = (newData, timeFrame) =>{

        let stocksList = []

        const updatedDataJson = newData.map((item, index) =>{

            stocksList.push(item['symbol'])

            if (timeFrame == "Intraday") {
                const hi = item['high']
                const ltp = item['ltp']
                const op = item['open']
                const lo = item['low']
                const cl_1 = item['cl_1']
                const vol_1 = item['vol_1']
                const hi_1 = item['hi_1']
                const lo_1 = item['lo_1']
                const volume = item['volume']
                const r2 = item['r2']
                const r1 = item['r1']
                const pp = item ['pp']
                const s1 = item['s1']
                const s2 = item['s2']
                const vwap_5min = item['vwap_5min']
                const mo_range_lo = item['mo_range_lo']
                const mo_range_hi =  item['mo_range_hi']
                const patterns = item['patterns']
    
                const change_5min = item['change_5min']
                const change_15min = item['change_15min']
    
                const ltp_op = (((ltp-op)/op)*100).toFixed(2)
                const hi_ltp = (((hi-ltp)/ltp)*100).toFixed(2)
                const ltp_lo = (((ltp-lo)/lo)*100).toFixed(2)
                const ltp_vwap_5min = (((ltp-vwap_5min)/vwap_5min)*100).toFixed(2)
                const gapeOpen = (((op-cl_1)/cl_1)*100).toFixed(2)
                const morningRange = (((mo_range_hi-mo_range_lo)/mo_range_lo)*100).toFixed(2) || undefined
                const vol_ch = (((volume-vol_1)/vol_1)*100).toFixed(2)
                const ltp_r2 = (((ltp-r2)/r2)*100).toFixed(2)
                const ltp_r1 = (((ltp-r1)/r1)*100).toFixed(2)
                const ltp_pp = (((ltp-pp)/pp)*100).toFixed(2)
                const ltp_s1 = (((ltp-s1)/s1)*100).toFixed(2)
                const ltp_s2 = (((ltp-s2)/s2)*100).toFixed(2)
                const opEqulas = op==hi ? "high":  op==lo ? "low": null
                const hi_yhi = (((hi-hi_1)/hi_1)*100).toFixed(2)
                const lo_ylo = (((lo-lo_1)/lo_1)*100).toFixed(2)
                
                return {...item, ltp_op, hi_ltp, ltp_lo, ltp_vwap_5min, gapeOpen, morningRange, vol_ch, ltp_pp, ltp_r1, ltp_r2, ltp_s1, ltp_s2, opEqulas, hi_yhi, lo_ylo, change_5min, change_15min, patterns}
            }

            if (timeFrame == "Daily") {

                const hi = item['high']
                const ltp = item['ltp']
                const op = item['open']
                const lo = item['low']
                const cl_1 = item['cl_1']
                const volume = item['volume']
                const r2 = item['r2']
                const r1 = item['r1']
                const pp = item ['pp']
                const s1 = item['s1']
                const s2 = item['s2']

                const dma_50d = item['dma_50d']

                const ltp_op = (((ltp-op)/op)*100).toFixed(2)                               //day change
                const ltp_cl_1 = (((ltp-cl_1)/cl_1)*100).toFixed(2)                         // change
                const day_range = (((hi-lo)/lo)*100).toFixed(2)                                 // range
                const topWick = ((hi - Math.max(op, ltp))*100/hi).toFixed(2)
                const bottomWick = ((Math.min(op, ltp) - lo)*100/lo).toFixed(2)
                const tradedValue = (volume*ltp/10000000).toFixed(2)
                const avg_vol = item['avg_vol']
                const ltp_r2 = (((ltp-r2)/r2)*100).toFixed(2)
                const ltp_r1 = (((ltp-r1)/r1)*100).toFixed(2)
                const ltp_pp = (((ltp-pp)/pp)*100).toFixed(2)
                const ltp_s1 = (((ltp-s1)/s1)*100).toFixed(2)
                const ltp_s2 = (((ltp-s2)/s2)*100).toFixed(2)
                const ltp_50dma = (((ltp-dma_50d)/dma_50d)*100).toFixed(2)
                const ch_3d = item['ch_3d']
                const ch_5d = item['ch_5d']
                const patterns = item['patterns']
                
                return {...item, ltp_op, ltp_cl_1, day_range, topWick, bottomWick, tradedValue, avg_vol, ltp_pp, ltp_r1, ltp_r2, ltp_s1, ltp_s2, ltp_50dma, ch_3d, ch_5d, patterns}
            }

            // 'name', 'sec', 'symbol', 'ltp', 'd_high', 'd_low', 'd_vol', 'cl_1', 'vol_1', 'avg_vol', 'open', 'ch_3m', 'ch_5m'
            //[["Symbol", true, "str", "symbol"], ["Name", true, "str", "name"], ["Sector", true, "str", "sec"], ["Month Change", true, "num", "MON_CH"], ["Range", true, "num", "RANGE"], ["Top Wick", true, "num", "topWick"], ["Bottom Wick", true, "num", "bottomWick"], ["Traded Value", true, "num", "VALUE"], ["Volme", true, "num", "VOL"], ["Avg. Volume", true, "num", "AVG_VOL"], ["LTP-R2", true, "num", "LTP_R2"], ["LTP-R1", true, "num", "LTP_R1"], ["LTP-PI", true, "num", "LTP_PI"], ["LTP-S1", true, "num", "LTP_S1"], ["LTP-S2", true, "num", "LTP_S2"], ["3M Change", true, "num", "3MON_CH"], ["5M Change", true, "num", "5MON_CH"], ["Candle", true, "str", "CANDLE"], ["Pattern", true, "str", "PATTERN"]]
            if (timeFrame == "Monthly") {
                const hi = Math.max(item['high'], item['d_high'])
                const ltp = item['ltp']
                const op = item['open']
                const lo = Math.min(item['low'], item['d_low'])
                const cl_1 = item['cl_1']
                const volume = item['volume'] + item['d_vol']
                

                const month_ch = (((ltp-op)/op)*100).toFixed(2)                                     //month change
                const month_range = (((hi-lo)/lo)*100).toFixed(2)                                   //month range
                const topWick = ((hi - Math.max(op, ltp))*100/hi).toFixed(2)
                const bottomWick = ((Math.min(op, ltp) - lo)*100/lo).toFixed(2)
                const tradedValue = (volume*ltp/10000000).toFixed(2)
                const avg_vol = item['avg_vol']
                const change_3M = item['ch_3m']
                const change_5M = item['ch_5m']
                
                return {...item, month_ch, month_range, topWick, bottomWick, tradedValue, avg_vol, change_3M, change_5M}
            }

        })

        setInstrumentsList(stocksList)
        setDataAndFilterData(updatedDataJson, timeFrame)

    }

    useEffect(() =>{

        if (tableTimeFrameOpts){
            for (let index = 0; index < tableTimeFrameOpts.length; index++) {
                if (tableTimeFrameOpts[index][1] == true){
                    fetchData(tableTimeFrameOpts[index][0])
                    break
                }
            }
        }

    }, [tableHeadingOpts, tableTimeFrameOpts])

    useEffect(() => {
        if (!filteredData && instrumentsList) {
            resetFilteredData()
        }
    }, [instrumentsList])
    


    const handleTableHeadingOptsChange = (e) => {
        const newTableHeadingOpts = tableHeadingOpts.map((item) => {
            // item[0] == e.target.value ? [item[0], !item[1]] : item
            // item[0] == e.target.value ? [...item][1] : item

            if (item['name'] == e.target.value) {
                item = {...item, 'selected': !item['selected']}
                return item
            }
            
            return item
    });
        setTableHeadingOpts(newTableHeadingOpts);
        // localStorage.setItem("tableHeadingOpts", JSON.stringify(newTableHeadingOpts))
    };

    const handleTableTimeFrameOptsChange = (e) => {
        const timeFrame = e.target.value
        const newtableTimeFrameOpts = tableTimeFrameOpts.map((item) => (
            item[0] === timeFrame ? [item[0], true] : [item[0], false]
        ))
        setTableTimeFrameOpts(newtableTimeFrameOpts);
        // localStorage.setItem("tableTimeFrameOpts", JSON.stringify(newtableTimeFrameOpts));

        if (timeFrame == 'Daily'){
            setTableHeadingOpts(dailyHeading)
            setIndexingKey("d")
        } 
        else if (timeFrame == 'Monthly') {
            setTableHeadingOpts(monthlyHeading)
            setIndexingKey("m")
        } 
        else if (timeFrame == 'Intraday') {
            setTableHeadingOpts(intradayHeading)
            setIndexingKey("i")
        }

    }

    
    const handleSelectAllorNone = (e) =>{
        if (e.target.value == "SelectAll"){

            const newTableHeadingOpts = tableHeadingOpts.map((item) =>{
                item = {...item, 'selected': true}
                return item
            })

            setTableHeadingOpts(newTableHeadingOpts)
        } 
        else if (e.target.value == "SelectNone") {

            const newTableHeadingOpts = tableHeadingOpts.map((item) =>{
                item = {...item, 'selected': false}
                return item
            })
            
            setTableHeadingOpts(newTableHeadingOpts)
        }
    }

    const filterInstrumentsBySearch = (e, attribute) => {

        const searchValue = e.target.value
        const keyPressed = e.nativeEvent.key
        let newFilteredData = filteredData

        if (searchValue == null || searchValue.trim() == "") {

            data.map((item) =>{
                const itemSymbol = item["symbol"]
                newFilteredData = {...newFilteredData, [itemSymbol]: {...filteredData[itemSymbol], [attribute]: true}}
            })

            setFilteredData(newFilteredData)
        }

        // else if (keyPressed == "Enter") {
        else {
            let filteredInstruments = [];
            let keywords = searchValue.split(" ");

            let filteredItemNames = []

            //ERROR: filter(), map(), reduce() and other methods, all have different usecases
            keywords.map((keyword) =>{

                if(keyword.trim() != "") {

                    data.map((item) =>{

                        const searchName = item[attribute]
                        const itemSymbol = item["symbol"]

                        if (searchName && searchName.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())){
                            filteredItemNames.push(itemSymbol)
                        }

                    })

                }

            })

            data.map((item) =>{
                const itemName = item["symbol"]
                if (filteredItemNames.some( i => i == itemName)){
                    newFilteredData = {...newFilteredData, [itemName]: {...filteredData[itemName], [attribute]: true}}
                }
                else {
                    newFilteredData = {...newFilteredData, [itemName]: {...filteredData[itemName], [attribute]: false}}
                }
            })
            
            setFilteredData(newFilteredData)
        }
    }

    const filterInstrumentsByValue = (minValue, maxValue, attribute) => {

        let newFilteredData = filteredData

        const minValueNumber = Number(minValue)
        const maxValueNumber = Number(maxValue)

        data.map((item) =>{
            const attrValue = Number(item[attribute])
            const itemName = item["symbol"]

            // Error:  if (minValue <= attrValue <= maxValue)  => this won't work  because
            //In JavaScript, the expression is interpreted as follows:
            // First, Number(minValue) <= Number(attrValue) is evaluated.
            // This results in a boolean value (true or false).
            // Then, this boolean value is compared to Number(maxValue).
            // Since true is converted to 1 and false is converted to 0, the comparison will always return true because any number compared to a boolean value in this way will not produce the intended result.
            
            if (minValue && !maxValue){

                if (minValueNumber <= attrValue){
                    newFilteredData = {...newFilteredData, [itemName]: {...newFilteredData[itemName], [attribute]: true}}
                }
                else {
                    newFilteredData = {...newFilteredData, [itemName]: {...newFilteredData[itemName], [attribute]:false}}
                }
            }

            else if ( maxValue && !minValue){

                if (attrValue <= maxValueNumber){
                    newFilteredData = {...newFilteredData, [itemName]: {...newFilteredData[itemName], [attribute]: true}}
                } else {
                    newFilteredData = {...newFilteredData, [itemName]: {...newFilteredData[itemName], [attribute]:false}}
                }
            }

            else if ((minValue && maxValue)) {

                if (minValueNumber <= attrValue && attrValue <= maxValueNumber) {
                    newFilteredData = {...newFilteredData, [itemName]: {...newFilteredData[itemName], [attribute]: true}}
                } else {
                    newFilteredData = {...newFilteredData, [itemName]: {...newFilteredData[itemName], [attribute]:false}}
                }
            }

            else if ((!minValue && !maxValue)) {

                    newFilteredData = {...newFilteredData, [itemName]: {...newFilteredData[itemName], [attribute]: true}}

            }
            
        })

        setFilteredData(newFilteredData)

    }

    const sortData = (attribute, isAssending) =>{

        let sortedData;
        if (data && isAssending){
            sortedData = [...data].sort((item1, item2) =>{return (Number(item1[attribute] - item2[attribute]))})
        }
        else if (data) {
            sortedData = [...data].reverse((item1, item2) =>{return (Number(item1[attribute] - item2[attribute]))})
        }

        setData(sortedData)
    }

    const renderTooltip = (des) => (
            <span style={{backgroundColor:'grey', padding:'0.25rem', border:'darkgrey solid 1px', borderRadius:'.3rem', color:'white', fontSize:'0.75rem'}}>
                {des}
            </span>
      );

    return (
        <div className='Home' style={{margin: '1rem'}}>

            <div className='TableOptions'>
                <Table>
                    <tbody>
                        <tr>
                            <td style={{backgroundColor: 'LightGray', width: '30vw'}}>Time Frame</td>
                                <td style={{textAlign: 'left'}}>
                                {tableTimeFrameOpts.map((item, index) => (
                                    // Error: you cannot use an if statement directly inside the JSX. use turnary operator
                                    <Button 
                                        key={index}
                                        variant="info" 
                                        size='sm' 
                                        value={item[0]} 
                                        onClick={handleTableTimeFrameOptsChange}
                                        // Error: In JSX, inline styles must be specified as an object.
                                        // Additionally, JavaScript object keys must be in camelCase format, so text-decoration becomes textDecoration.
                                        style={{
                                            backgroundColor: item[1] ? 'LightGray' : 'snow',
                                            margin: '0.07rem'
                                            }}
                                    >
                                        {item[0]}
                                    </Button>
                                ))}
                            </td>
                        </tr>

                        <tr>
                            <td style={{backgroundColor: 'LightGray', width: '30vw'}}>Heading Options</td>
                            <td style={{textAlign: 'left'}}>
                            {tableHeadingOpts.map((item, index) => (
                                // Error: you cannot use an if statement directly inside the JSX. use turnary operator
                                <Button 
                                    key={index}
                                    variant="info" 
                                    size='sm' 
                                    value={item['name']} 
                                    onClick={handleTableHeadingOptsChange}
                                    // Error: In JSX, inline styles must be specified as an object.
                                    // Additionally, JavaScript object keys must be in camelCase format, so text-decoration becomes textDecoration.
                                    style={{
                                        backgroundColor: item['selected'] ? 'LightGray' : 'snow',
                                        margin: '0.07rem'
                                        }}
                                >
                                    {item['name']}
                                </Button>
                            ))}
                                <hr style={{margin:'0.25rem'}}></hr>
                                <Button variant="info" size='sm' value='SelectAll' onClick={handleSelectAllorNone} style={{backgroundColor: 'lightblue', margin: '0.07rem'}}>Select All</Button>
                                <Button variant="info" size='sm' value='SelectNone' onClick={handleSelectAllorNone} style={{backgroundColor: '#ffb3b2', margin: '0.07rem'}}>Select None</Button>
                                <Button variant="info" size='sm' value='SelectNone' onClick={resetFilteredData} style={{backgroundColor: '#ffb3b2', margin: '0.07rem'}}>Clear filters</Button>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>

            <div className='main-table'>
                <Table striped bordered hover>
                    <thead style={{position:'sticky', top: '0px', zIndex:'0'}}>
                    <tr>
                        
                        {tableHeadingOpts.map((item, index) => (
                            item['selected'] ? <th key={index + "_" + indexingkey} style={{backgroundColor:'lightgray'}} >
                                        <div style={{display:'flex', justifyContent:'center'}}>
                                            <OverlayTrigger
                                            overlay={renderTooltip(item['tip'])}
                                            >
                                                <div>{item['name']}</div>

                                            </OverlayTrigger>
                                            {/* TODO : Implement component instead below code*/}
                                            {
                                                item['type'] == 'str' ? <TableHeadingFilterSearchOnly filterInstrumentsBySearch={filterInstrumentsBySearch} attribute={item['key']}/> : <TableHeadingFilter filterInstrumentsByValue={filterInstrumentsByValue} attribute={item['key']} sortData={sortData}/>
                                            }

                                        </div>
                                      </th>: null
                        ))}

                    </tr>
                    </thead>
                    <tbody>
                        {/* Error: If js arrow function is inclosed inside {} then explicite return is required 
                                If js arrow funstion is inclosed inside () then no explicite return is required */}
                        {/* item = {"Instrument1": {"open":50, "high":55, "low":45, "close":50}} */}

                        {data.map((item, index) =>{
                            const SYMBOL = item['symbol']
                            let isFilteredOut = false

                            if (filteredData && filteredData[SYMBOL]) {

                                const filteringValues = Object.values(filteredData[SYMBOL])

                                // isFilteredOut = Object.values(filteringValues).some(value => value === false);
                                isFilteredOut = filteringValues.some(value => value === false)

                            }

                            return(
                                !isFilteredOut &&
                                <tr key={index}>
                                    {tableHeadingOpts.map((headingOpt, i) => (
                                        headingOpt['selected'] ? <td key={i}>{item[headingOpt['key']]}</td> : null   // headingOpt[3] containes key of data json
                                    ))}
                                </tr>
                            )
                            
                        })}

                    </tbody>
                </Table>
            </div>

        </div>
    )

}

export default Home