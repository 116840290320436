import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL } from '../../constants';

const Protected = () => {
    const [message, setMessage] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token');

                const response = await axios.get(`${API_URL}/protected`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                        // Authorization: null
                    }
                });

                setMessage(response.data.logged_in_as.email);

            } catch (error) {
                setMessage('You are not authorized');
            }
        };

        fetchData();
    }, []);

    return <div>{message}</div>;
};

export default Protected;
