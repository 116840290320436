import axios from 'axios'
import React, { useState } from 'react'
import { Button, Badge, Form, FloatingLabel } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { API_URL } from '../../constants'

const UserResetPassword = () => {

    const navigate = useNavigate()

    const [userEmail, setUserEmail] = useState(null)
    const [userPassword, setUserPassword] = useState(null)
    const [userNewPassword, setUserNewPassword] = useState(null)
    const [msg, setUserMsg] = useState(null)

    const handleResetPassword = async (e) =>{
        e.preventDefault();
        
        try {
            
            const response = await axios.post(`${API_URL}/resetPassword`, {userEmail, userPassword, userNewPassword});

            setUserMsg(response.data.msg)
            if (response.status == 200) {
                setTimeout(() => {
                    navigate('/user/logIn')
                    window.location.reload()
                }, 2000);
            }
            
        } catch (error) {
            setUserMsg(error.response.data.msg)
        }
    }

  return (
    <>
    <div className='passwordResetForm' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    <div style={{maxWidth: '90vw', minWidth: '29rem', marginTop: '5rem', padding: '1rem', border:'grey solid 0.05rem', boxShadow:'10px 10px 10px 10px lightgrey', backgroundImage: 'linear-gradient(to left, lightblue , lightgrey)', borderRadius: '0.5rem'}}>
        <h2><Badge bg="secondary" style={{marginBottom: "1rem"}}>Reset Password</Badge></h2>
        <Form style={{display: 'grid', justifyContent: 'center'}} onSubmit={handleResetPassword}>

            <FloatingLabel style={{maxWidth: '85vw', minWidth: '25rem'}}
                controlId="floatingInput"
                label="User email address"
                className="mb-3"
                >
                <Form.Control type="email" value={userEmail} onChange={(e) => setUserEmail(e.target.value)} placeholder="user@company.com"/>
            </FloatingLabel>

            <FloatingLabel
                controlId="floatingInput"
                label="Password"
                className="mb-3"
                >
                <Form.Control type="password" value={userPassword} onChange={(e) => setUserPassword(e.target.value)} placeholder="password"/>
            </FloatingLabel>

            <FloatingLabel
                controlId="floatingInput"
                label="New password"
                className="mb-3"
                >
                <Form.Control type="password" value={userNewPassword} onChange={(e) => setUserNewPassword(e.target.value)} placeholder="New password"/>
            </FloatingLabel>


            {/* <div style={{display: 'flex', margin: '0.5rem'}}>
            <label>New Passwordfdfdfdfdfd : </label>
            <input style={{background:'transparent', border: 'None', marginLeft: '1rem', borderRadius: '0.25rem', border: '1px solid grey'}}
                type="password"
                name="newUserPasword"
                placeholder="new password"
                value={userNewPassword}
                onChange={(e) => setUserNewPassword(e.target.value)}
            >
            </input>
            </div> */}
            <Button style={{marginTop: '1rem', marginBottom: '1rem'}} type='submit'>Submit</Button>
            {msg && <div className="alert alert-dark" role="alert"> {msg} </div>}
        </Form>
        
    </div>
    </div>
    </>
  )
}

export default UserResetPassword